import { Row } from '@tanstack/react-table';
import classNames from 'classnames';

import { useAmendmentDetailsModal } from '@xemplo/amendment-details-modal';
import { useGetAmendmentsQueryV2 } from '@xemplo/amendment-query';
import { CreateAmendment } from '@xemplo/create-amendment';
import { Amendment, AmendmentStatusIds, SortDirection } from '@xemplo/gp-types';
import { transformStatus } from '@xemplo/gp-utils';
import { IconButton, IconButtonSize } from '@xemplo/icon-button';
import { DawnAddPlus16 } from '@xemplo/icons';

import { columns } from './amendment-list-widget.helper';
import * as S from './amendment-list-widget.styles';
export function AmendmentListWidget() {
  const { updateModal } = useAmendmentDetailsModal();
  const { data, isLoading } = useGetAmendmentsQueryV2({
    requestParams: {
      per_page: 8,
      page: 1,
      sort: { requestedDate: SortDirection.DESC },
      q: {
        statusId: transformStatus([
          AmendmentStatusIds.CREATED,
          AmendmentStatusIds.REJECTED,
        ]).toString(),
        showComplete: false,
        showArchived: false,
      },
    },
  });

  const handleRowClick = (
    e: React.MouseEvent<Element, MouseEvent>,
    row: Row<Amendment>
  ) => {
    updateModal(row.original);
  };

  const count = data?.result?.rows.length;

  return (
    <S.CustomWidget
      title="Amendments"
      count={count}
      className={classNames({ 'is-empty': !count })}
      button={
        <CreateAmendment
          renderButton={(onClick) => (
            <IconButton
              id="create-new-amendment"
              ariaLabel="Create new amendment button"
              onClick={onClick}
              size={IconButtonSize.Small}
            >
              <DawnAddPlus16 />
            </IconButton>
          )}
        />
      }
    >
      {!!count && <S.SubTitle>Recently added</S.SubTitle>}
      <S.AmendmentListTable
        tableOverflowControlMinWidth={312}
        data={data?.result?.rows ?? []}
        columns={columns}
        enablePagination={false}
        enableSorting={false}
        enableRowSelection={false}
        pageCount={data?.result?.total_Pages ?? 0}
        totalRowCount={data?.result?.total ?? 0}
        isLoading={isLoading}
        onBodyRowClick={handleRowClick}
        noResultProps={{ message: 'You are up to date!' }}
      />
    </S.CustomWidget>
  );
}

export default AmendmentListWidget;
