import { AmendmentCreate, CreateTerminationPayload } from '@xemplo/gp-types';

import { FormFields } from '../create-amendment.types';

import { FUTURE_SCHEDULED_PAYRUN } from './form/page-1/select-payrun';
import {
  AmountMethodOptions,
  ParsedCsvItem,
} from './form/page-2/bulk-upload/bulk-upload.types';

export function preparePayload(data: FormFields, csvData: ParsedCsvItem[] = []) {
  const payload: AmendmentCreate = {
    amendmentTypeId: data.changeType,
    amendmentTaskTypeId: data.changeTask,
    amendmentSubTypeId: data.changeTaskSubType,
    businessUnitId: data.businessUnit ?? null,
    note: data.notes ?? null,
    adhocEffectiveDate: formatPayloadDate(data?.adhocEffectiveDate),
    adhocPayDate: formatPayloadDate(data?.adhocPayDate),
    amendmentSummary: data.amendmentSummary ?? '',
    amendmentAssigneeAndAmount: getAmendmentAssigneeAmount(data, csvData),
    LumpSumCalculationMethod: data.taxCalculationMethod ?? null,
    LumpSumNumberOfPayPeriods: data.paymentPeriods ? +data.paymentPeriods : null,
    ...(data.payrun &&
      data.payrun !== FUTURE_SCHEDULED_PAYRUN && { payrunId: data.payrun }),
    ...getRecurringData(data),
  };
  return payload;
}

function formatPayloadDate(date?: string | null) {
  return date ? new Date(date).toISOString() : null;
}

function getRecurringData(data: FormFields) {
  return data.recurringType
    ? {
        recurringDurationTypeId: data.recurringType,
        recurringMaximumAmount: data.recurringMaxAmount ?? null,
        recurringExpiryDate: formatPayloadDate(data?.recurringEndDate),
      }
    : {};
}

function getAmendmentAssigneeAmount(data: FormFields, csvData: ParsedCsvItem[]) {
  if (data.amountMethod === AmountMethodOptions.Variable) {
    return csvData.map((item) => ({
      assignToId: item.id.toString(),
      amount: Number(item.rate),
      units: Number(item.units),
    }));
  }
  if (!data.appliesTo) return [];
  //If the amendment is a termination the appliesTo is a single string instead of an array.
  const appliesTo = Array.isArray(data.appliesTo) ? data.appliesTo : [data.appliesTo];
  const value =
    appliesTo?.map((id: string) => ({
      assignToId: id,
      amount: Number(data.rate),
      units: Number(data.units),
    })) ?? [];

  data.terminatedWorkers?.forEach((id) => {
    value.push({
      assignToId: id,
      amount: Number(data.rate),
      units: Number(data.units),
    });
  });

  return value;
}

export const prepareTerminationPayload = (data: FormFields) => {
  // appliesTo is a single string instead of an array for termination
  const appliesTo = data.appliesTo?.toString();
  const payload: CreateTerminationPayload = {
    assignToId: appliesTo,
    amendmentTypeId: data.changeType,
    amendmentTaskTypeId: data.changeTask,
    amendmentSubTypeId: data.changeTaskSubType,
    businessUnitId: data.businessUnit ?? null,
    note: data.notes ?? null,
    amendmentSummary: data.amendmentSummary?.toString() ?? '',
  };
  return payload;
};
