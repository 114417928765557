type Id = string | number | null;

export const AmendmentAPIV2 = {
  editAmendment: (amendmentId: Id) => `api/v2/amendments/${amendmentId}`,
  editTerminationAmendment: (amendmentId: Id) =>
    `api/v2/amendments/termination/${amendmentId}`,
  editPayInstructionAmendment: (amendmentId: Id) =>
    `api/v2/amendments/payinstruction/${amendmentId}`,
  getAmendments: `api/v2/amendments`,
  getAmendmentById: (amendmentId: Id) => `api/v2/amendments/${amendmentId}`,
  createAmendment: `api/v2/amendments`,
  createPayInstruction: 'api/v2/amendments/payinstruction',
  createTermination: 'api/v2/amendments/termination',
};
