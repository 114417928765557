import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  useCreateAmendmentV2,
  useCreatePayInstructionV2,
  useCreateTerminationV2,
} from '@xemplo/amendment-query';
import { ButtonIconPosition, ButtonType } from '@xemplo/button';
import { AmendmentTypes, ChangeTaskTypes } from '@xemplo/gp-types';
import { DawnArrowLongLeft16 } from '@xemplo/icons';
import { useModal } from '@xemplo/modal';
import { ToastType, useToast } from '@xemplo/toast';

import { PLACEHOLDER_OPTION } from '../create-amendment.helper';
import { FormFields } from '../create-amendment.types';

import {
  AmountMethodOptions,
  ParsedCsvItem,
} from './form/page-2/bulk-upload/bulk-upload.types';
import { handlePayInstruction } from './form/page-2/pay-instruction';
import { StepOne } from './form/step-one';
import { StepThree } from './form/step-three';
import { StepTwo } from './form/step-two';
import {
  preparePayload,
  prepareTerminationPayload,
} from './create-amendment-form.helper';
import { ModalBodyProps } from './create-amendment-form.types';

const DEFAULT_ERROR_MESSAGE = 'An unexpected error occurred. Please try again later.';

export const useCreateAmendmentForm = (props: Readonly<ModalBodyProps>) => {
  const { setPromptVisible, onSuccess, payrunModeValues, selectedWorkers } = props;
  const navigate = useNavigate();
  const methods = useForm<FormFields>({
    defaultValues: {
      changeType: payrunModeValues?.payrunId
        ? AmendmentTypes.PAYRUN
        : +PLACEHOLDER_OPTION,
      payrun: payrunModeValues?.payrunId ?? undefined,
      businessUnit: payrunModeValues?.businessUnitId ?? undefined,
      appliesTo: [],
    },
  });

  const payrunMode = !!payrunModeValues?.payrunId && !!payrunModeValues?.businessUnitId;

  const [step, setStep] = useState(payrunMode ? 2 : 1);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [csvData, setCsvData] = useState<ParsedCsvItem[]>([]);
  const [csvDataWarnings, setCsvDataWarnings] = useState<string[]>([]);
  const { configureModal, toggleModal } = useModal();
  const { addToast } = useToast();
  const watchAmountMethod = methods.watch('amountMethod');
  const watchChangeTask = methods.watch('changeTask');

  function handleSuccessfulCreate(type: string) {
    watchChangeTask !== ChangeTaskTypes.TERMINATION && onSuccess?.();
    addToast({
      text: `${type} created`,
      type: ToastType.Confirmation,
    });
    toggleModal(false);
  }

  const { mutateAsync } = useCreateAmendmentV2({
    onSuccess: () => handleSuccessfulCreate('Amendment'),
    onError: (error) => {
      const errorMessage =
        error?.response?.data?.validationErrors?.errors[0].validatedColumn;
      setErrorMessage(errorMessage ?? DEFAULT_ERROR_MESSAGE);
    },
  });

  const { mutateAsync: createPayInstruction } = useCreatePayInstructionV2({
    onSuccess: () => handleSuccessfulCreate('Pay Instruction'),
    onError: (error) => {
      const errors = error.response?.data?.errors;
      const allErrorMessages = Object.values(errors ?? {})
        .flat()
        .join(', ');
      const simpleError = error.message;
      setErrorMessage(allErrorMessages ?? simpleError ?? DEFAULT_ERROR_MESSAGE);
    },
  });

  const { mutateAsync: createTerminationAsync } = useCreateTerminationV2({
    onSuccess: () => handleSuccessfulCreate('Termination'),
    onError: (error) => {
      const errors = error.response?.data?.errors;
      const allErrorMessages = Object.values(errors ?? {})
        .flat()
        .join(', ');
      const simpleError = error.message;
      setErrorMessage(allErrorMessages ?? simpleError ?? DEFAULT_ERROR_MESSAGE);
    },
  });

  const handleNextStep = async () => {
    const isStepValid = await methods.trigger();
    if (isStepValid) {
      setStep((prev) => prev + 1);
    }
  };

  const handleSubmit = methods.handleSubmit(async (data) => {
    if (data.changeTask === ChangeTaskTypes.PAY_INSTRUCTION) {
      return handlePayInstruction({ data, createPayInstruction });
    }
    if (data.changeTask === ChangeTaskTypes.TERMINATION) {
      const response = await createTerminationAsync(prepareTerminationPayload(data));
      navigate(`/payrun/amendment/${response.result[0]}`);
      return;
    }
    return mutateAsync(preparePayload(data, csvData));
  });

  function getPrimaryButton() {
    switch (step) {
      case 1:
        return {
          onClick: handleNextStep,
          label: 'Next',
          ariaLabel: 'Next page button',
          type: ButtonType.Primary,
        };
      case 2:
        return watchAmountMethod === AmountMethodOptions.Variable
          ? {
              onClickAsync: handleNextStep,
              disabled: csvData.length === 0,
              label: 'Next',
              ariaLabel: 'Next page button',
              type: ButtonType.Primary,
            }
          : {
              onClickAsync: handleSubmit,
              label: 'Submit',
              ariaLabel: 'Submit',
              type: ButtonType.Primary,
            };
      case 3:
        return {
          onClickAsync: handleSubmit,
          label: 'Submit',
          ariaLabel: 'Submit',
          type: ButtonType.Primary,
        };

      default:
        return undefined;
    }
  }

  function getTertiaryButton() {
    switch (step) {
      case 1:
        return {
          onClick: () => setPromptVisible(true),
          label: 'Cancel',
          ariaLabel: 'Cancel',
          type: ButtonType.Tertiary,
        };
      case 2:
        if (payrunMode) {
          return {
            onClick: () => setPromptVisible(true),
            label: 'Cancel',
            ariaLabel: 'Cancel',
            type: ButtonType.Tertiary,
          };
        }
        return {
          onClick: () => {
            setStep((prev) => prev - 1);
            methods.reset();
          },
          label: 'Back',
          ariaLabel: 'Back',
          type: ButtonType.Tertiary,
          icon: <DawnArrowLongLeft16 />,
          iconPosition: ButtonIconPosition.Leading,
        };
      case 3:
        return {
          onClick: () => {
            setStep((prev) => prev - 1);
          },
          label: 'Back',
          ariaLabel: 'Back',
          type: ButtonType.Tertiary,
          icon: <DawnArrowLongLeft16 />,
          iconPosition: ButtonIconPosition.Leading,
        };

      default:
        return undefined;
    }
  }
  useEffect(() => {
    configureModal({
      footer: {
        btnPrimary: getPrimaryButton(),
        btnTertiary: getTertiaryButton(),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configureModal, step, watchAmountMethod, csvData, csvDataWarnings]);

  function getFormStep() {
    switch (step) {
      case 1:
        return <StepOne />;
      case 2:
        return (
          <StepTwo
            setCsvData={setCsvData}
            setCsvDataWarnings={setCsvDataWarnings}
            selectedWorkers={selectedWorkers}
          />
        );
      case 3:
        return <StepThree csvData={csvData} csvDataWarnings={csvDataWarnings} />;
      default:
        return null;
    }
  }

  return {
    methods,
    getFormStep,
    errorMessage,
  };
};
