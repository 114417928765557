import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AMENDMENT_QUERY_KEYS, PAYRUN_QUERY_KEYS } from '@xemplo/gp-constants';
import {
  AmendmentCreate,
  CreateTerminationPayload,
  EditAmendmentPayload,
  EditPayInstructionPayload,
  EditTerminationPayload,
  LegacyListPagedResult,
  PayInstructionPayload,
} from '@xemplo/gp-types';
import { useHttpClient } from '@xemplo/http';
import { apiUrlBuilder } from '@xemplo/url-helper';
import { useXemploApiContext } from '@xemplo/xemplo-api-provider';

import { AmendmentAPIV2 } from './amendment-query.constants';
import {
  useCreateAmendmentPropsV2,
  useCreatePayInstructionPropsV2,
  useCreateTerminationPropsV2,
  useEditAmendmentProps,
  useEditPayInstructionProps,
  useEditTerminationProps,
} from './amendment-query.types';

export const useEditAmendment = (props: useEditAmendmentProps) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const queryClient = useQueryClient();
  const url = apiUrlBuilder(
    AmendmentAPIV2.editAmendment(props.amendmentId),
    applicationApiUrl
  );

  return useMutation({
    mutationFn: (payload: Partial<EditAmendmentPayload>) => {
      return client.patch(url, payload).then((resp) => resp.data);
    },
    onSuccess: (data, variants, context) => {
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENT_BY_ID],
      });
      props.onSuccess?.(data, variants, context);
    },
    onError: props.onError,
  });
};

export const useEditTermination = (props: useEditTerminationProps) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const queryClient = useQueryClient();
  const url = apiUrlBuilder(
    AmendmentAPIV2.editTerminationAmendment(props.amendmentId),
    applicationApiUrl
  );

  return useMutation({
    mutationFn: (payload: Partial<EditTerminationPayload>) => {
      return client.patch(url, payload).then((resp) => resp.data);
    },
    onSuccess: (data, variants, context) => {
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENT_BY_ID],
      });
      props.onSuccess?.(data, variants, context);
    },
    onError: props.onError,
  });
};

export const useEditPayInstruction = (props: useEditPayInstructionProps) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const queryClient = useQueryClient();
  const url = apiUrlBuilder(
    AmendmentAPIV2.editPayInstructionAmendment(props.amendmentId),
    applicationApiUrl
  );

  return useMutation({
    mutationFn: (payload: EditPayInstructionPayload) => {
      const formData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          formData.append(key, value);
        }
      });
      return client.patch(url, formData).then((resp) => resp.data);
    },
    onSuccess: (data, variants, context) => {
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENT_BY_ID],
      });
      props.onSuccess?.(data, variants, context);
    },
    onError: props.onError,
  });
};

export const useCreateAmendmentV2 = (props: useCreateAmendmentPropsV2) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const queryClient = useQueryClient();
  const url = apiUrlBuilder(AmendmentAPIV2.createAmendment, applicationApiUrl);

  return useMutation({
    mutationFn: (payload: AmendmentCreate) =>
      client.post<LegacyListPagedResult<string>>(url, payload).then((resp) => resp.data),
    onSuccess: (data, variants, context) => {
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_WORKERS],
      });
      props.onSuccess?.(data, variants, context);
    },
    onError: props.onError,
  });
};

export const useCreatePayInstructionV2 = (props: useCreatePayInstructionPropsV2) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const queryClient = useQueryClient();
  const url = apiUrlBuilder(AmendmentAPIV2.createPayInstruction, applicationApiUrl);

  return useMutation({
    mutationFn: (payload: PayInstructionPayload) => {
      const formData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value);
        }
      });
      return client.post(url, formData).then((resp) => resp.data);
    },
    onSuccess: (data, variants, context) => {
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_WORKERS],
      });
      props.onSuccess?.(data, variants, context);
    },
    onError: props.onError,
  });
};

export const useCreateTerminationV2 = (props: useCreateTerminationPropsV2) => {
  const { applicationApiUrl } = useXemploApiContext();
  const { client } = useHttpClient();
  const queryClient = useQueryClient();
  const url = apiUrlBuilder(AmendmentAPIV2.createTermination, applicationApiUrl);

  return useMutation({
    mutationFn: (payload: CreateTerminationPayload) => {
      return client
        .post<LegacyListPagedResult<string>>(url, payload)
        .then((resp) => resp.data);
    },
    onSuccess: (data, variants, context) => {
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [AMENDMENT_QUERY_KEYS.GET_AMENDMENTS],
      });
      queryClient.invalidateQueries({
        queryKey: [PAYRUN_QUERY_KEYS.GET_PAYRUN_WORKERS],
      });
      props.onSuccess?.(data, variants, context);
    },
    onError: props.onError,
  });
};
